const setCookie = () => {
    const date = new Date();
    date.setTime(date.getTime() + (12 * 60 * 60 * 1000));
    document.cookie = `rejected-check=1; expires=${date.toGMTString()}`;
};

const checkCookie = (name) => {
    let value = document.cookie;
    let start = value.indexOf(` ${name}=`);
    if (start === -1) start = value.indexOf(`${name}=`);
    if (start === -1) {
        value = null;
    } else {
        start = value.indexOf('=', start) + 1;
        let end = value.indexOf(';', start);
        if (end === -1) {
            end = value.length;
        }
        value = unescape(value.substring(start, end));
    }
    return value;
};

class BorlabsTrigger {
    constructor(groups) {
        this.groups = groups;
    }

    init() {
        if (!checkCookie('rejected-check')) {
            this.showCookieBox();
            setCookie();
        }
    }

    hasUserRejected() {
        let hasRejected = false;
        this.groups.forEach((group) => {
            if (!window.BorlabsCookie.checkCookieGroupConsent(group)) {
                hasRejected = true;
            }
        });

        return hasRejected;
    }

    getExpiredDatesDifference() {
        const days = [];

        this.groups.forEach((group) => {
            const today = new Date();
            const expires = new Date(window.BorlabsCookie.getCookie(group).expires);
            const diffTime = Math.abs(today.getTime() - expires.getTime());
            days.push(Math.ceil(diffTime / (1000 * 3600 * 24)));
        });

        return days;
    }

    isRejectedOlderThen(days) {
        let isOlder = false;
        const dayCounts = this.getExpiredDatesDifference();
        const year = new Date().getFullYear();
        const daysOfYear = year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0) ? 365 : 366;
        dayCounts.forEach((dayCount) => {
            if (dayCount >= (daysOfYear - days)) {
                isOlder = true;
            }
        });

        return isOlder;
    }

    showCookieBox() {
        if (this.hasUserRejected() && this.isRejectedOlderThen(7)) {
            window.BorlabsCookie.showCookieBox();
        }
    }
}

const borlabsTrigger = {
    init() {
        const instance = new BorlabsTrigger([
            'essential',
            'external-media',
            'marketing',
            'statistics',
        ]);
        instance.init();
    },
};

export default borlabsTrigger;

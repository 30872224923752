/**
 * Modul für Input Felder
 * Setzt die Klasse 'active' sobald Text eingetippt wurde.
 * Wird z.B. zum Entfernen oder Animieren von Labels benötigt.
 */
class FormInput {
    constructor($element) {
        this.$element = $element;
        this.$element.on('input', () => this.handleChange());
    }

    handleChange() {
        if (this.$element.val()) {
            this.$element.addClass('active');
        } else {
            this.$element.removeClass('active');
        }
    }
}

const formInputExport = {
    init() {
        const $inputs = jQuery('.js-form input');

        if ($inputs.length) {
            $inputs.each((i, el) => {
                // eslint-disable-next-line no-new
                new FormInput(jQuery(el));
            });
        }
    },
};

export default formInputExport;

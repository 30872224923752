class FirmLocation {
    constructor($select, $output, $phone, $email) {
        this.$select = $select;
        this.$output = $output;
        this.$phone = $phone;
        this.$email = $email;

        this.$select.on('change', () => {
            this.getLocation();
        });

        this.getLocation();
    }

    /**
     * Gets the relevant data from the selected option
     */
    getLocation() {
        const $selectedOption = this.$select.find(':selected');
        this.$output.html(this.$select.val());

        const phone = $selectedOption.data('phone');
        FirmLocation.checkAttribute(phone, this.$phone);
        this.$phone.html(phone);

        const mailAddress = $selectedOption.data('mail');
        FirmLocation.checkAttribute(mailAddress, this.$email);
        this.$email.html(mailAddress).attr('href', `mailto:${mailAddress}`);
    }

    /**
     *
     * @param attribute
     * @param $element
     */
    static checkAttribute(attribute, $element) {
        const parent = $element.parent();
        if (!attribute || !attribute.length) {
            parent.hide();
        } else {
            parent.show();
        }
    }
}

const firmLocationExport = {
    init() {
        const $select = jQuery('.js-firm-location-select');
        const $output = jQuery('.js-firm-location-output');
        const $email = jQuery('.js-firm-location-mail');
        const $phone = jQuery('.js-firm-location-phone');

        if ($select) {
            this.object = new FirmLocation($select, $output, $phone, $email);
        }
    },
};

export default firmLocationExport;

export default function collapsibles() {
    const allCollapsibles = document.querySelectorAll('.js-collapsible');

    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0
            && rect.left >= 0
            && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    allCollapsibles.forEach((collapsible) => {
        const toggle = collapsible.querySelector('.js-collapsible-toggle');
        const openText = collapsible.querySelector('.js-collapsible-toggle-text-open');
        const closeText = collapsible.querySelector('.js-collapsible-toggle-text-close');

        toggle.addEventListener('click', (event) => {
            event.preventDefault();
            const isClosed = collapsible.classList.contains('is-closed');

            if (isClosed) {
                collapsible.classList.remove('is-closed');
                closeText.removeAttribute('hidden');
                openText.setAttribute('hidden', 'true');
                collapsible.setAttribute('aria-expanded', true);
            } else {
                collapsible.classList.add('is-closed');
                openText.removeAttribute('hidden');
                closeText.setAttribute('hidden', 'true');
                collapsible.setAttribute('aria-expanded', false);

                if (!isInViewport(toggle)) {
                    toggle.scrollIntoView();
                }
            }
        });
    });
}

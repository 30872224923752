/**
 * Modul für Elemente, die per Trigger aufsliden.
 * Trigger im Template mit 'js-toggle-trigger' definieren und
 * ID der jeweilige Ziel-Box als 'data-target' des Triggers definieren.
 *
 */
class BoxOpener {
    constructor($trigger) {
        this.$trigger = $trigger;
        this.$element = jQuery(this.$trigger.data('target'));

        this.$trigger.click(() => this.handleTrigger());
    }

    handleTrigger() {
        this.$element.slideToggle('slow');
    }
}

const boxOpenerExport = {
    init() {
        const $trigger = jQuery('.js-toggle-trigger');

        if ($trigger.length) {
            $trigger.each((index, element) => {
                // eslint-disable-next-line no-new
                new BoxOpener(jQuery(element));
            });
        }
    },
};

export default boxOpenerExport;

import ajaxService from '../services/ajaxService';

class LoadMorePosts {
    constructor(stage, trigger) {
        this.stage = stage;
        this.trigger = trigger;

        if (!this.trigger) return;

        this.page = 2;
        this.posts = [];
        this.totalPages = this.stage.dataset.totalPages;

        this.setLastItem();
        this.loadMore();
        this.trigger.addEventListener('click', this.handleTrigger.bind(this));
    }

    handleTrigger(event) {
        event.preventDefault();

        if (this.totalPages <= 1) {
            return;
        }

        this.loadMore()
            .then(() => {
                this.posts.forEach((post, index) => {
                    const doc = new DOMParser().parseFromString(post, 'text/html').body.firstChild;
                    if (index === (this.posts.length - 1)) {
                        doc.classList.add('js-last-archive-item');
                    }
                    this.stage.appendChild(doc);
                });

                let yOffset = 0;
                if (window.innerWidth > 768) {
                    yOffset = 100;
                }

                window.scrollTo(0, this.stage.querySelector('.js-last-archive-item')
                    .getBoundingClientRect().top + window.pageYOffset - yOffset);
                this.stage.querySelector('.js-last-archive-item')
                    .classList
                    .remove('js-last-archive-item');
            });
        this.page += 1;
    }

    setLastItem() {
        const lastItem = this.stage.querySelector('.js-last-archive-item');
        if (!lastItem) {
            const allItems = this.stage.querySelectorAll('.article-teaser--horizontal');
            if (allItems) {
                allItems[allItems.length - 1].classList.add('js-last-archive-item');
            }
        }
    }

    loadMore() {
        const checkboxValues = [];
        const checkboxes = document.querySelectorAll('.js-search-checkbox:checked');

        checkboxes.forEach((item) => {
            checkboxValues.push(item.value);
        });

        return new Promise((resolve) => {
            ajaxService
                // eslint-disable-next-line no-undef
                .get(`${loadmore.restUrl}juve/v1/search/`, {
                    searchType: checkboxValues.length !== 0 ? checkboxValues : [],
                    searchTerm: document.querySelector('.js-search-input').value,
                    page: this.page,
                })
                .then((response) => {
                    this.posts = response;
                    this.toggleLoadMoreButton();

                    resolve();
                });
        });
    }

    toggleLoadMoreButton() {
        if (this.page > parseInt(this.totalPages, 10)) {
            this.trigger.classList.add('disabled');
        } else {
            this.trigger.classList.remove('disabled');
        }
    }
}

const loadMorePosts = {
    init() {
        const trigger = document.querySelector('.js-search-trigger');
        const stage = document.querySelector('.js-search-stage');

        if (stage && trigger) {
            // eslint-disable-next-line no-new
            new LoadMorePosts(stage, trigger);
        }
    },
};

export default loadMorePosts;

import navigation from './modules/navigation';
import boxOpener from './modules/boxOpener';
import formInputs from './modules/formInputs';
import slider from './modules/slider';
import fixedBadge from './modules/fixedBadge';
import openDetail from './modules/opendetail';
import acfAuthor from './modules/acfAuthor';
import overlay from './modules/overlay';
import firmLocation from './modules/firmLocation';
import advertisingOverlay from './modules/advertisingOverlay';
import borlabsTrigger from './modules/borlabsTrigger';
import loadMorePosts from './modules/loadMorePosts';
import searchForm from './modules/searchForm';
import expander from './modules/expander';
import expertProfileLoadMore from './modules/expertProfileLoadMore';
import scrollTop from './modules/scrollTop';
import collapsibles from './modules/collapsible';
import stickyLinks from './modules/stickyLinks';
import googleMapsConsentEventRunner from './modules/googleMapsConsentEventRunner';

window.googleMapsConsentEventRunner = googleMapsConsentEventRunner;
jQuery(document).ready(() => {
    navigation.init();
    boxOpener.init();
    formInputs.init();
    slider.init();
    fixedBadge.init();
    openDetail.init();
    acfAuthor.init();
    overlay.init();
    firmLocation.init();
    loadMorePosts.init();
    searchForm.init();
    expander.init();
    advertisingOverlay.init();
    borlabsTrigger.init();
    expertProfileLoadMore.init();

    scrollTop();
    stickyLinks();
    collapsibles();

    window.jp.advertising.loadOverlay();
});

import debounce from 'lodash/debounce';

class Viewport {
    constructor() {
        this.updateViewportSize();
        this.updateViewportSize = debounce(this.updateViewportSize.bind(this), 500);
        jQuery(window).resize(this.updateViewportSize);
    }

    updateViewportSize() {
        this.width = jQuery(window).width();
        this.height = jQuery(window).height();
    }
}

const viewport = new Viewport();

export default viewport;

/*
* Ranking Slider
* */

class OpenDetail {
    constructor() {
        this.$element = jQuery('.js-company-card-button');
        this.$element.click((event) => { OpenDetail.handleClick(event); });
    }

    static handleClick(event) {
        const $clickedElement = jQuery(event.currentTarget);
        OpenDetail.activateContent($clickedElement);
    }

    static activateContent($element) {
        if (!$element.hasClass('active')) {
            const $activeAccordions = jQuery('.js-company-card-button.active');
            $activeAccordions.removeClass('active');
            $activeAccordions.find('.js-company-card-box').slideUp('slow');
        }

        $element.toggleClass('active');
        $element.find('.js-company-card-box').slideToggle('slow');
    }
}

const openDetailExport = {
    init() {
        this.object = new OpenDetail();

        const {hash} = window.location;
        if (!hash) {
            return;
        }

        const $element = jQuery(`${hash}.js-company-card-button`);
        if ($element.length) {
            OpenDetail.activateContent($element);
            jQuery('html, body').animate({scrollTop: $element.offset().top}, 'slow');
        }
    },
};

export default openDetailExport;

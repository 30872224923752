class Expander {
    constructor(element) {
        this.element = element;
        this.button = this.element.querySelector('.js-expander-button');
        this.button.addEventListener('click', this.toggle.bind(this));
        this.list = this.element.querySelector('.js-expander-list');
    }

    toggle() {
        if (this.element.classList.contains('active')) {
            this.list.style.height = '0px';
            this.element.classList.remove('active');
            return;
        }

        this.element.classList.add('active');
        this.list.style.height = `${this.list.scrollHeight}px`;
    }
}

const expander = {
    init() {
        const expandersElements = document.querySelectorAll('.js-expander');
        const expanders = [];

        expandersElements.forEach((element) => {
            // eslint-disable-next-line no-new
            expanders[element.id] = new Expander(element);
        });

        // wenn ein Hash in der URL ist, kann das ein Sprungziel für den Expander sein sein
        const {hash} = window.location;
        if (!hash) {
            return;
        }

        // wenn es einen Expander mit dieser ID gibt, wird der geöffnet
        const selectedElement = document.querySelector(`${hash}.js-expander`);

        if (selectedElement) {
            selectedElement.scrollIntoView();
            const expanderName = hash.substring(1);
            expanders[expanderName].toggle();
        }
    },
};

export default expander;

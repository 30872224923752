export default function stickyLinks() {
    const targets = document.querySelectorAll('.js-stickylinks-target');
    const links = document.querySelectorAll('.js-stickylinks-link');

    if (targets.length) {
        const threshold = [];
        for (let i = 0; i <= 1.0; i += 0.01) {
            threshold.push(i);
        }

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.intersectionRatio.toFixed(2) >= 0.99) {
                        links.forEach((link) => {
                            link.classList.remove('is-active');
                        });

                        if (entries.length) {
                            // eslint-disable-next-line max-len
                            const intersectingEntries = entries.filter((thisEntry) => thisEntry.isIntersecting);

                            let upperEntry = null;
                            intersectingEntries.forEach((thisEntry) => {
                                // eslint-disable-next-line max-len
                                if (!upperEntry || thisEntry.boundingClientRect.y <= upperEntry.boundingClientRect.y) {
                                    upperEntry = thisEntry;
                                }
                            });

                            if (upperEntry) {
                                const selector = `#link-${upperEntry.target.id}`;
                                const newLink = document.querySelector(selector);
                                newLink.classList.add('is-active');
                                newLink.scrollIntoView({block: 'nearest', inline: 'center'});
                            }
                        } else {
                            const newLink = document.querySelector(`#link-${entry.target.id}`);
                            newLink.classList.add('is-active');
                            newLink.scrollIntoView({block: 'nearest', inline: 'center'});
                        }
                    }
                }
            });
        };

        const options = {
            root: null,
            rootMargin: '-80px 0px 0px 0px',
            threshold,
        };

        const observer = new IntersectionObserver(callback, options);

        targets.forEach((element) => {
            observer.observe(element);
        });

        // wenn einer der Links angeklickt wird, wird er auch explizit gehighlightet
        links.forEach((link) => {
            link.addEventListener('click', (event) => {
                links.forEach((oldlink) => {
                    oldlink.classList.remove('is-active');
                });

                event.target.classList.add('is-active');
            });
        });
    }
}

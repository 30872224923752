import ajaxService from '../services/ajaxService';

class ExpertProfileLoadMore {
    constructor(stage) {
        this.profiles = [];
        this.stage = stage;
        this.paginationWrapper = this.stage.querySelector('.js-expert-pagination');
        this.errorMessage = this.stage.querySelector('.js-errormessage');
        this.wrapper = this.stage.querySelector('.js-expert-overview');
        this.usedIds = this.stage.querySelector('.js-used-ids');
        this.postsLength = this.stage.querySelector('.js-posts-length');
        this.trigger = this.stage.querySelector('.js-load-more-trigger');
        this.lastIndex = 0;
        this.handleTrigger(null, true);
        this.trigger.addEventListener('click', this.handleTrigger.bind(this));
    }

    handleTrigger(event = null, firstCall = false) {
        if (event !== null) {
            event.preventDefault();
        }

        if (this.lastIndex >= this.postsLength.value) {
            return;
        }

        this.loadMore()
            .then(() => {
                if (this.profiles.length) {
                    this.profiles.forEach((profile, index) => {
                        const doc = new DOMParser().parseFromString(profile, 'text/html').body.firstChild;
                        if (!firstCall && index === 0) {
                            doc.classList.add('js-last-expert-item');
                        }
                        this.wrapper.appendChild(doc);
                    });
                } else {
                    this.errorMessage.removeAttribute('hidden');
                }

                this.stage.querySelector('.js-expert-loading')
                    .classList
                    .add('d-none');

                let yOffset = 0;
                if (window.innerWidth > 768) {
                    yOffset = 100;
                }

                this.lastIndex += this.profiles.length;
                this.toggleLoadMoreButton();

                document.querySelector('.js-expert-current').innerText = this.lastIndex;
                document.querySelector('.js-expert-progress').setAttribute('value', this.lastIndex);
                document.querySelector('.js-expert-progress').setAttribute('max', this.postsLength.value);
                document.querySelector('.js-expert-max').innerText = this.postsLength.value;

                if (Number(this.postsLength.value) === 1) {
                    document.querySelector('.js-expert-headline').innerText = `${this.postsLength.value} Expert`;
                } else if (Number(this.postsLength.value) === 0) {
                    document.querySelector('.js-expert-headline').innerText = `${this.postsLength.value} Experts found`;
                } else {
                    document.querySelector('.js-expert-headline').innerText = `${this.postsLength.value} Experts`;
                }

                if (!firstCall) {
                    window.scrollTo(0, this.wrapper.querySelector('.js-last-expert-item')
                        .getBoundingClientRect().top + window.pageYOffset - yOffset);
                    this.wrapper.querySelector('.js-last-expert-item')
                        .classList
                        .remove('js-last-expert-item');
                }
                if (firstCall) {
                    window.scrollTo(0, 0);
                }
            });
    }

    loadMore() {
        const expertise = document.querySelector('.js-expertise');
        const country = document.querySelector('.js-country');
        const data = {
            expertise: expertise.value,
            country: country.value,
            usedIds: this.usedIds.value,
        };

        return new Promise((resolve) => {
            ajaxService
                // eslint-disable-next-line no-undef
                .get(`${loadmore.restUrl}juve/v1/expert-profile/`, data)
                .then((response) => {
                    this.profiles = response.posts;
                    this.usedIds.value += response.usedIds;
                    this.postsLength.value = response.postsLength;
                    resolve();
                });
        });
    }

    toggleLoadMoreButton() {
        if (Number(this.postsLength.value) > 0) {
            this.paginationWrapper.removeAttribute('hidden');
        } else {
            this.paginationWrapper.setAttribute('hidden', 'hidden');
        }

        if (this.lastIndex >= parseInt(this.postsLength.value, 10)) {
            this.trigger.classList.add('disabled');
        } else {
            this.trigger.classList.remove('disabled');
        }
    }
}

const expertProfileLoadMore = {
    init() {
        const stage = document.querySelector('.js-expert-wrapper');

        if (stage) {
            // eslint-disable-next-line no-new
            new ExpertProfileLoadMore(stage);
        }
    },
};

export default expertProfileLoadMore;

class Slider {
    constructor($element) {
        this.$element = $element;
        const config = {
            dots: false,
            slidesToShow: $element.data('items'),
            centerMode: $element.data('center'),
            // eslint-disable-next-line max-len
            prevArrow: '<button class="slick-arrow slick-prev"><svg width="23px" height="24px" viewBox="0 0 23 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs></defs> <g id="Start" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="start_ultrawide" transform="translate(-513.000000, -4587.000000)" stroke="#FFFFFF" stroke-width="2"> <g id="arrow-left" transform="translate(494.000000, 4555.000000)"> <g id="Group-3" transform="translate(20.000000, 33.000000)"> <polyline id="arrow-left" transform="translate(5.875000, 11.000000) rotate(-90.000000) translate(-5.875000, -11.000000) " points="-5.125 16.5 5.875 5.5 5.875 5.5 16.875 16.5"></polyline> <path d="M21.9814024,11 L0.763628915,11" id="Path-4"></path> </g> </g> </g> </g></svg></button>',
            // eslint-disable-next-line max-len
            nextArrow: '<button class="slick-arrow slick-next"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs></defs> <g id="Start" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="start_ultrawide" transform="translate(-1651.000000, -4610.000000)" stroke="#FFFFFF" stroke-width="2"> <g id="company-slider" transform="translate(374.000000, 4279.000000)"> <g id="arrow-right" transform="translate(1259.000000, 299.000000)"> <g id="Group-2" transform="translate(18.000000, 33.000000)"> <polyline id="arrow-right" transform="translate(16.875000, 11.000000) rotate(-270.000000) translate(-16.875000, -11.000000) " points="5.875 16.5 16.875 5.5 16.875 5.5 27.875 16.5"></polyline> <path d="M21.9814024,11 L0.763628915,11" id="Path-4"></path> </g> </g> </g> </g> </g></svg></button>',
            responsive: [],
            slidesToScroll: 1,
            focusOnSelect: false,
        };

        if ($element.data('sync-slider') !== undefined) {
            config.asNavFor = `#${$element.data('sync-slider')}`;
        }

        if (!$element.data('arrows') && $element.data('arrows') !== undefined) {
            config.arrows = false;
        }

        if ($element.data('dots')) {
            config.dots = true;
        }

        if ($element.children().length < $element.data('items')) {
            config.centerMode = false;
        }

        if ($element.data('variablewidth')) {
            config.variableWidth = $element.data('variablewidth');
        }

        if ($element.data('adaptiveheight')) {
            config.adaptiveHeight = $element.data('adaptiveheight');
        }

        if ($element.data('responsive')) {
            config.responsive = [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                }];
        }

        if ($element.data('items-responsive')) {
            config.responsive = [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: $element.data('items'),
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: $element.data('items-responsive'),
                    },
                },
            ];
        }

        if ($element.data('map')) {
            config.responsive = [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                    },
                }];
        }

        if ($element.data('responsive-center-mode')) {
            config.responsive = [
                {
                    breakpoint: 576,
                    settings: {
                        centerMode: true,
                    },
                }];
        }

        this.$element.slick(config);

        // Refresh map slider to prevent display errors #917
        if ($element.data('map')) {
            setTimeout(() => {
                this.$element[0].slick.refresh();
                this.$element.css('height', 'auto');
            }, 3000);
        }
    }
}

const navigationExport = {
    init() {
        this.obj = [];

        jQuery('.js-slider').each((index, element) => {
            this.obj.push(new Slider(jQuery(element)));
        });
    },
};

export default navigationExport;

const $window = jQuery(window);

class FixedBadge {
    constructor($element) {
        this.$element = $element;
        this.bottomOfEl = this.$element.parent().offset().top + this.$element.parent().height();

        $window.on('scroll', () => this.handleScroll());
    }

    handleScroll() {
        if ($window.scrollTop() > this.bottomOfEl) {
            this.$element.addClass('active');
        } else {
            this.$element.removeClass('active');
        }
    }
}

const fixedBadgeExport = {
    init() {
        const $badge = jQuery('.js-fixed-badge');
        if ($badge.length) {
            $badge.each((index, el) => {
                // eslint-disable-next-line no-new
                new FixedBadge(jQuery(el));
            });
        }
    },
};

export default fixedBadgeExport;

class Overlay {
    constructor(trigger) {
        this.$trigger = jQuery(trigger);
        this.$document = jQuery(document);
        this.$parent = this.$trigger.parent();
        this.$overlay = this.$parent.find('.js-overlay-box');
        this.$buttonCancel = this.$parent.find('.js-button-cancel');

        this.$trigger.on('click', (event) => {
            this.handleTrigger(event);
        });
        this.$buttonCancel.on('click', (event) => {
            this.handleTrigger(event);
        });
        this.$document.on('click', (event) => {
            if ((this.$parent.has(event.target)).length === 0) {
                if (this.$overlay.hasClass('active')) {
                    this.$overlay.removeClass('active');
                }
            }
        });
    }

    handleTrigger(event) {
        event.preventDefault();
        this.$overlay.toggleClass('active');
    }
}

const overlayExport = {
    init() {
        const $triggers = jQuery('.js-overlay-trigger');

        if ($triggers.length) {
            $triggers.each((index, trigger) => {
                this.overlayObject = new Overlay(jQuery(trigger));
            });
        }
    },
};

export default overlayExport;

/**
 * Durchläuft eine NodeList und prüft welches Element ausgewählt(checked) ist.
 * @param group {NodeList} Liste der Elemente aus der Gruppe.
 * @returns {Node|Element} Gibt das ausgewählte Element zurück.
 */
export function getCheckedElement(group) {
    return Array.from(group).find((item) => item.checked);
}

export function getFormElements(form) {
    return form.querySelectorAll('input, select, textarea');
}

/**
 * Konvertiert ein Object zu einem FormData-Object. Diese Methode kann dazu verwendet,
 * um Daten (vor allem Files) via Ajax zu versenden.
 * @param data {object} Das entsprechende Objekt, dass konvertiert werden soll.
 * @returns {FormData} Das konvertierte Objekt.
 */
export function objectToFormData(data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });

    return formData;
}

/**
 * Mapped ein HTML-Form-Element zu einem Object.
 * @param form {Element} Das HTML-Form-Element.
 * @returns {Object}
 */
export function formToObject(form) {
    const object = {};
    const formElements = getFormElements(form);

    Array.from(formElements).forEach((element) => {
        if (!element.name) {
            return;
        }

        const group = form.querySelectorAll(`[name="${element.name}"]`);

        if (group.length > 1) {
            const checkedElement = getCheckedElement(group);
            const key = (!checkedElement) ? group[0] : checkedElement;

            object[key.name] = key.value;
        } else {
            let {value} = element;

            if (element.type === 'file') {
                [value] = element.files;
            }

            if (element.type === 'checkbox') {
                value = !!element.checked;
            }

            object[element.name] = value;
        }
    });

    return object;
}

import throttle from 'lodash/throttle';
import viewport from './viewport';

/**
 * Modul zum Steuern der Navigation auf mobile.
 * Trigger Button öffnet und schließt die Navigation.
 * Resize führt zum Schließen der Navigation, um Bugs
 * zu verhindern.
 */
class Navigation {
    constructor($trigger, $element, $siteWrapper) {
        this.$trigger = $trigger;
        this.$element = $element;
        this.$siteWrapper = $siteWrapper;

        this.viewport = {width: viewport.width, height: viewport.height};

        this.$navLinks = this.$element.find('a');

        this.$trigger.click(() => this.handleTrigger());

        this.handleResize = throttle(this.handleResize.bind(this), 500);

        jQuery(window).resize(() => this.handleResize());

        this.$navLinks.focus(() => this.openNav());
    }

    handleTrigger() {
        this.$trigger.toggleClass('nav-open');
        this.$siteWrapper.toggleClass('nav-open');
        jQuery('body').toggleClass('nav-open');
        this.$element.toggleClass('open');

        jQuery('.js-search-input-header').focus();
    }

    handleResize() {
        if (this.viewport.width !== viewport.width) {
            this.closeNav();
        }
        this.viewport = {width: viewport.width, height: viewport.height};
    }

    closeNav() {
        this.$trigger.removeClass('nav-open');
        this.$siteWrapper.removeClass('nav-open');
        jQuery('body').removeClass('nav-open');
        this.$element.removeClass('open');
    }

    openNav() {
        if (viewport.width <= 991) {
            this.$trigger.addClass('nav-open');
            this.$siteWrapper.addClass('nav-open');
            jQuery('body').addClass('nav-open');
            this.$element.addClass('open');
        }
    }
}

const navigationExport = {
    init() {
        const $trigger = jQuery('.js-nav-trigger');

        if ($trigger.length) {
            this.obj = new Navigation($trigger, jQuery('.js-nav'), jQuery('.js-site-wrapper'));
        }
    },
};

export default navigationExport;

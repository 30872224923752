class AdOverlay {
    constructor(adOverlay, cookieName, adIdCookieName) {
        this.adOverlay = adOverlay;
        this.cookieName = cookieName;
        this.adIdCookieName = adIdCookieName;
        this.closeButton = adOverlay.querySelector('.js-ad-overlay-closebutton');

        this.closeButton.addEventListener('click', this.closeOverlay.bind(this));
        adOverlay.addEventListener('click', this.closeOverlay.bind(this));
    }

    closeOverlay(event) {
        // Nur der Schließen-Button und der Backdrop dürfen das Schließen auslösen
        if (event.target !== this.adOverlay && event.target !== this.closeButton) { return; }

        const CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 1);

        this.adOverlay.classList.add('is-hidden');
        document.cookie = `${this.cookieName}=true;expires=${CookieDate.toUTCString()};`;
        document.cookie = `${this.adIdCookieName}=true;expires=${CookieDate.toUTCString()};`;
        document.querySelector('body').classList.remove('scrolllock');
    }
}

const advertisingOverlay = {
    init() {
        /**
         * Checks if cookie with given name exists
         * @param cookieName
         * @returns {boolean}
         */
        const cookieExists = (cookieName) => document.cookie.split(';')
            .some((item) => item.trim()
                .indexOf(`${cookieName}=`) === 0);

        window.jp = window.jp || {};
        window.jp.advertising = window.jp.advertising || {};

        window.jp.advertising.loadOverlay = () => {
            /**
             * Das Banner soll nur eingeblendet werden, wenn in Borlabs das "Essential"-Paket
             * bestätigt wurde. Im Falle des Erstaufrufs wird dies im Callback von Borlabs gemacht,
             * ansonsten wird die Variable in `base.html.twig` gesetzt.
             */
            if (cookieExists('borlabs-cookie')) {
                /*
                * Die Funktion wird einmal während des Initialisierens des JS gerufen und noch
                * einmal wenn Borlabs Cookie bestätigt, dass die "Essential Cookies" bestätigt
                * wurden. Das ist notwendig, damit das Banner in jedem Fall angezeigt wird, da
                * beim ersten Aufruf der Seite die Initialisierung vor der Bestätigung der
                * Cookies geschieht.
                * `adOverlayRunOnce` stellt sicher, dass das Banner nur einmal eingeblendet
                * wird.
                */
                if (!window.jp.advertising.centerAdOverlayRunOnce) {
                    window.jp.advertising.centerAdOverlayRunOnce = true;

                    const centerAdOverlay = document.querySelector('.js-center-ad-overlay');

                    if (centerAdOverlay) {
                        // in diesem Element wird auch per Klasse die AD-Id hinterlegt. z.B. 'a-31'
                        const adId = centerAdOverlay.querySelector('.g-single').classList.value.replace(' ', '');
                        const adIdCookieName = `centerAd-${adId}`;

                        /**
                         * Das Overlay soll angezeigt werden, wenn
                         * - es noch nie angezeigt wurde ODER
                         * - es schon angezeigt wurde, aber die aktuelle Ad noch
                         *   nicht ausgespielt wurde
                         */
                        if (!cookieExists('hideCenterAdOverlay') || (cookieExists('hideCenterAdOverlay')
                            && !cookieExists(adIdCookieName))) {
                            if (centerAdOverlay.classList.contains('is-hidden')) {
                                // eslint-disable-next-line no-new
                                new AdOverlay(centerAdOverlay, 'hideCenterAdOverlay', adIdCookieName);

                                centerAdOverlay.classList.remove('is-hidden');
                                document.querySelector('body').classList.add('scrolllock');
                            }
                        }
                    }
                }

                if (!window.jp.advertising.bottomAdOverlayRunOnce) {
                    window.jp.advertising.bottomAdOverlayRunOnce = true;

                    const bottomAdOverlay = document.querySelector('.js-bottom-ad-overlay');

                    if (bottomAdOverlay) {
                        // in diesem Element wird auch per Klasse die AD-Id hinterlegt. z.B. 'a-31'
                        const adId = bottomAdOverlay.querySelector('.g-single').classList.value.replace(' ', '');
                        const adIdCookieName = `bottomAd-${adId}`;

                        /**
                         * Das Overlay soll angezeigt werden, wenn
                         * - es noch nie angezeigt wurde ODER
                         * - es schon angezeigt wurde, aber die aktuelle Ad
                         *   noch nicht ausgespielt wurde
                         */
                        if (!cookieExists('hideBottomAdOverlay') || (cookieExists('hideBottomAdOverlay')
                            && !cookieExists(adIdCookieName))) {
                            if (bottomAdOverlay.classList.contains('is-hidden')) {
                                // eslint-disable-next-line no-new
                                new AdOverlay(bottomAdOverlay, 'hideBottomAdOverlay', adIdCookieName);

                                bottomAdOverlay.classList.remove('is-hidden');
                            }
                        }
                    }
                }
            }
        };
    },
};

export default advertisingOverlay;

// Modul für ACF Autor Radiobutton
/* eslint-disable no-undef */

class AcfAuthor {
    constructor(repeater) {
        this.$repeater = jQuery(repeater);
        this.$addAuthor = this.$repeater.find('a.acf-button');
        this.$blocks = null;
        this.updateButtons();
        this.$addAuthor.on('click', () => {
            setTimeout(() => {
                this.updateButtons();
            }, 300);
        });
    }

    updateBlocks() {
        this.$blocks = this.$repeater.find('td.acf-fields');
    }

    updateButtons() {
        this.updateBlocks();
        this.$blocks.each((index, block) => {
            const $radioButtons = jQuery(block)
                .find('input:radio[id^="acf-field_juve_sponsored_autoren-"]');
            const $select = jQuery(block)
                .find('.acf-field-juve-sponsored-autoren-object');
            const $firstName = jQuery(block)
                .find('.acf-field-juve-sponsored-autoren-firstname');
            const $lastName = jQuery(block)
                .find('.acf-field-juve-sponsored-autoren-lastname');
            const $upload = jQuery(block)
                .find('.acf-field-juve-sponsored-autoren-foto');
            const $contact = jQuery(block)
                .find('.acf-field-juve-sponsored-autoren-contact');
            const $elements = [$firstName, $lastName, $upload, $contact];
            $radioButtons.each((indexButtons, button) => {
                if (button.checked) {
                    if (button.value === '1') {
                        $firstName.hide();
                        $lastName.hide();
                        $upload.hide();
                        $contact.hide();
                        $select.show();
                    } else {
                        $firstName.show();
                        $lastName.show();
                        $upload.show();
                        $contact.show();
                        $select.hide();
                    }
                }
                jQuery(button)
                    .on('change', (event) => {
                        this.handleChange(event, $select, $elements);
                    });
            });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    handleChange(event, select, elements) {
        if (event.currentTarget.value === '1') {
            jQuery(elements)
                .each((index, element) => {
                    jQuery(element)
                        .hide();
                });
            jQuery(select)
                .show();
        }
        if (event.currentTarget.value === '2') {
            jQuery(elements)
                .each((index, element) => {
                    jQuery(element)
                        .show();
                });
            jQuery(select)
                .hide();
        }
    }
}

const acfAuthorExport = {
    init() {
        const $repeaters = jQuery('.acf-field-juve-sponsored-autoren');
        if ($repeaters.length) {
            $repeaters.each((index, repeater) => {
                this.acfAuthorObject = new AcfAuthor(jQuery(repeater));
            });
        }
    },
};

export default acfAuthorExport;

/* global ActiveXObject */

import {objectToFormData} from './formService';

class AjaxService {
    /**
     * AJAX Request
     * @param method {string} Methode zum Senden der Daten.
     * @param url {string} Die Ziel-Url
     * @param data {object} Object mit den zu sendenden Daten
     * @returns {Promise} Gibt eine Promise zurück die bei success ein Response Object beinhaltet.
     */
    static makeRequest(method = 'GET', url = '', data = {}) {
        return new Promise((resolve, reject) => {
            if (!url.length) {
                reject(Error('No AjaxUrl specified'));
            }

            let queryString = '';
            if (method === 'GET') {
                queryString = `?${Object.keys(data)
                    .map((key) => `${key}=${data[key]}`)
                    .join('&')}`;
            }

            const formData = objectToFormData(data);

            const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');

            xhr.open(method, url + queryString);
            xhr.onreadystatechange = () => {
                if (xhr.readyState > 3) {
                    let json = null;

                    try {
                        if (xhr.responseText) {
                            json = JSON.parse(xhr.responseText);
                        }
                    } catch (e) {
                        reject(e);
                    }

                    if (xhr.status === 200) {
                        resolve(json);
                    }

                    reject(xhr.statusText);
                }
            };

            if (method === 'GET') {
                xhr.send();
            }

            if (method === 'POST') {
                xhr.send(formData);
            }

            // eslint-disable-next-line no-promise-executor-return
            return xhr;
        });
    }
}

const ajaxService = {
    post(url, data) {
        return AjaxService.makeRequest('POST', url, data);
    },
    get(url, data) {
        return AjaxService.makeRequest('GET', url, data);
    },
};

export default ajaxService;

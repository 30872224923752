class SearchForm {
    constructor(element) {
        this.element = element;
        this.checkboxes = element.querySelectorAll('.js-search-checkbox');
        this.addEventListener();
    }

    reloadOnFilterChange() {
        this.element.submit();
    }

    addEventListener() {
        this.checkboxes.forEach((item) => {
            item.addEventListener('change', this.reloadOnFilterChange.bind(this));
        });
    }
}

const searchForm = {
    init() {
        const formElement = document.querySelector('.js-search-form');
        if (formElement) {
            // eslint-disable-next-line no-new
            new SearchForm(formElement);
        }
    },
};

export default searchForm;
